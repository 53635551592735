<template>

    <div class="div uk-padding uk-border-rounded" style="border: 1px solid black;position: relative;">
        <div class="uk-grid uk-child-width-expand@s" uk-grid>

            <div class="div">
                <strong>data "test"</strong>
                <div>{{test}}</div>
            </div>
            <div class="div">
                <strong>data "test2"</strong>
                <div>{{test2}}</div>
            </div>


            <div class="div">
                <strong>asyncOps status</strong>
               <div>{{asyncOps.asyncStatus || null}}</div>
            </div>


        </div>


    </div>

</template>

<script>
    import {ref, reactive, computed,  watchEffect} from 'vue';

    export default {

         setup (props) {
            let {asyncOps, asyncOpsReady} = asyncOperations(props, useStore());

             return {asyncOps, asyncOpsReady}
        },
        props: {
             /*
            asyncDataDefaults : {
                type : Object,
                default: {
                    requestAdapter : 'default', // should also work without this
                    responseAdapter: 'default' // should also work without this
                }
            }
            */

        },
        data: function () {

            return {
                test : 'test data not set',
                test2 : 'test2 data not set',
                dataForTest2 : {'dynamicCoolData' : 'value1'},

                asyncData : {
                   // simple version: just route for the data
                    test: 'test', // should also work with just string

                    // advanced version:
                    test2: {
                        // api url
                        target : 'sample',
                        //target : 'http://localhost:3001/api/sample',
                        // data for the request. use computed() composition to make reactive
                        data : computed(() => this.dataForTest2),
                        // options for the request: method, requestAdapter, responseAdapter
                        options : {
                            method: 'post',
                          //  requestUrlFull : true // will not append prefix to target, and instead use the full url.
                        //    responseRaw: true, // allows to get the full response from server
                      //       requestAdapter : 'default', // request adapter
                      //       responseAdapter : 'raw', // response adapter
                        },

                        /* static request data exmplae
                           data : {
                           'testDataKey' : 'testDataValue'
                       },
                       */
                    },


                }
            };
        },
        watch : {
             async asyncOpsReady () {


                 utilities.setClientTimeout(() => {
                 //    this.asyncOps.asyncCall('test', {'testData':'testBogle'}, {});
                   //  console.log('change value');
               //       this.dataForTest2 = {'dynamicCoolData' : 'value2'};
                 }, 3000);




                 utilities.setClientTimeout(() => {
  //  console.log('changing property');
  //  this.asyncData.sources.test = 'bogle';
   // this.asyncData.test = 'sample';
 //   console.log(this.asyncData.test);
  //  console.log('update');
  //  this.asyncData.test = 'test';
}, 1000);

                 utilities.setClientTimeout(() => {
                     //  console.log('changing property');
                     // this.asyncData.sources.test = 'bogle';
                     // this.asyncData.test = 'sample';
                     //   console.log(this.asyncData.test);
                 //    console.log('update 2');
                 //    delete this.asyncData.test;
                  //   this.test = null;

                 }, 2000);
                 utilities.setClientTimeout(() => {
                     //  console.log('changing property');
                     // this.asyncData.sources.test = 'bogle';
                     // this.asyncData.test = 'sample';
                     //   console.log(this.asyncData.test);
                 //    console.log('update 3');

                 //    this.asyncData.test = 'test';
                 }, 3000)
           //      let result = await this.asyncOps.call('test', {}, {});
            //     console.log('asyncCall result', result);
             //    console.log('reactive async ops ready');
         //        console.log('changed');
            //     let rawResponse = await this.requestAdapter.get('test', {});
          //       console.log('get result', rawResponse);
             //    let res = this.responseAdapter.parse(rawResponse, {});
            //     console.log('parsed result', res);
             }
        }

    }
</script>

<style scoped lang="scss">

</style>
